import React, { useState } from "react";
import CreateSnapshotButton from "./SnapshotButton.tsx";
import DatabaseSnapshotReplaceModal from "./DatabaseSnapshotReplaceModal.tsx"; // Import the updated modal
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

// Define types for the db object
interface Database {
  DBInstanceIdentifier: string;
  InstanceCreateTime: string;
  DBInstanceStatus: string;
}

interface DatabaseEntryProps {
  key: number;
  db: Database;
  onCopyClick: (db: Database) => void;
  onDestroyClick: (db: Database) => void;
  snapshots: any[]; // You should replace this with the proper type if possible
}

const DatabaseEntry: React.FC<DatabaseEntryProps> = ({
  key,
  db,
  onCopyClick,
  onDestroyClick,
  snapshots,
}) => {
  console.log(key);
  const [openModal, setOpenModal] = useState(false); // State to control the modal

  const handleRestoreClick = () => {
    setOpenModal(true); // Open the modal when the restore button is clicked
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const deleting = db.DBInstanceStatus === "deleting";
  const maintaining: boolean = ["backing-up", "modifying"].includes(
    db.DBInstanceStatus
  );

  return (
    <div
      className={`${
        deleting ? "bg-red-100" : "bg-white"
      } p-6 shadow-md rounded-lg mb-4 flex flex-col space-y-4`}
    >
      <div className="flex justify-between items-center">
        <pre className="text-xs font-mono p-2 bg-gray-100 w-full text-right flex justify-between items-center">
          <code className="text-md text-gray-800">
            {db.DBInstanceIdentifier}
          </code>
          <button
            onClick={() => onCopyClick(db)}
            className="ml-2 bg-gray-100 hover:bg-gray-200 hover:text-gray-50 text-center"
          >
            <ClipboardDocumentIcon className="h-6 w-6 text-gray-800, hover:text-gray-800" />
          </button>
        </pre>
      </div>
      <div className="text-sm text-gray-600">
        <p>Created: {new Date(db.InstanceCreateTime).toLocaleString()}</p>
        <p>Status: {db.DBInstanceStatus}</p>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col items-center">
          <CreateSnapshotButton
            dbIdentifier={db.DBInstanceIdentifier}
            deleting={deleting}
            maintaining={maintaining}
          />
        </div>
        <div className="flex flex-col items-center">
          {/* Trigger the new modal here */}
          <button
            onClick={handleRestoreClick}
            className={`min-w-[120px] px-4 gap-x-1 shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer ${
              deleting || maintaining
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-yellow-400 hover:bg-yellow-500"
            }`}
            disabled={deleting || maintaining}
          >
            Restore From Snapshot
          </button>
          {openModal && (
            <DatabaseSnapshotReplaceModal
              onClose={handleCloseModal} // Close the modal when it's done
              dbIdentifier={db.DBInstanceIdentifier}
              snapshots={snapshots}
            />
          )}
        </div>
        <div className="flex flex-col items-center">
          <button
            onClick={() => onDestroyClick(db)}
            className={`min-w-[120px] px-4 gap-x-1 shadow-md rounded-full inline-flex items-center justify-center ${
              deleting || maintaining
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-500"
            } text-white cursor-pointer`}
            disabled={deleting || maintaining}
          >
            Destroy Forever
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatabaseEntry;
