import React, { useState } from "react";
import { backendUrl } from "../constants";

interface RestoreSnapshotButtonProps {
  snapshotIdentifier: string;
}

const RestoreSnapshotButton: React.FC<RestoreSnapshotButtonProps> = ({
  snapshotIdentifier,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleCreateSnapshot = async () => {
    setLoading(true);
    setMessage(null);

    try {
      const response = await fetch(
        `${backendUrl}/snapshot_to_db/${snapshotIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ snapshotIdentifier: snapshotIdentifier }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setMessage(`Snapshot created: ${data.snapshot_id || "Success"}`);
    } catch (error) {
      console.error("Snapshot creation failed", error);
      setMessage("Failed to create snapshot.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={handleCreateSnapshot}
        className="px-4 gap-x-1 min-w-[80px] shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer bg-green-400 hover:bg-green-600"
        disabled={loading}
      >
        {loading ? "Restoring..." : "Restore Snapshot"}
      </button>
      {message && <p className="mt-2 text-sm text-gray-700">{message}</p>}
    </div>
  );
};

export default RestoreSnapshotButton;
