// SheetSelection.tsx
import React from "react";

interface SheetSelectionProps {
  sheets: string[];
  selectedSheets: string[];
  setSelectedSheets: React.Dispatch<React.SetStateAction<string[]>>;
  handleSubmit: () => void;
  clearSheets: () => void;
  setUploadStatus: React.Dispatch<
    React.SetStateAction<"success" | "failure" | "progress" | null>
  >;
}

const SheetSelection: React.FC<SheetSelectionProps> = ({
  sheets,
  selectedSheets,
  setSelectedSheets,
  handleSubmit,
  clearSheets,
  setUploadStatus,
}) => {
  setUploadStatus(null);
  return (
    <div className="py-5">
      <div className="max-h-80 bg-gray-50 dark:bg-gray-700 dark:text-gray-100 px-5 py-5 overflow-y-auto">
        <div className="grid grid-cols-2 gap-4">
          {" "}
          {/* Use grid to split into two columns */}
          {sheets.map((sheet, index) => (
            <div key={index} className="flex items-center">
              <input
                id={`checkbox-${index}`}
                type="checkbox"
                value={sheet}
                checked={selectedSheets.includes(sheet)}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setSelectedSheets((prev) =>
                    checked ? [...prev, value] : prev.filter((s) => s !== value)
                  );
                }}
                className="w-4 h-4 bg-gray-100 border-gray-300 rounded-sm dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor={`checkbox-${index}`}
                className="ms-4 text-sm font-medium text-gray-900 dark:text-gray-100"
              >
                {sheet}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="container grid py-5 grid-cols-8">
        <div className="col-span-2 flex items-center justify-center">
          <button
            onClick={() => {
              clearSheets();
            }}
            className="cancel-button px-4 gap-x-1 min-w-[120px] shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer bg-red-400 hover:bg-green-600"
          >
            Cancel
          </button>
        </div>
        <div className="col-span-4" />
        <div className="col-span-2 flex items-center justify-center">
          <button
            onClick={() => {
              handleSubmit();
              clearSheets();
              setUploadStatus("progress");
            }}
            className="px-4 gap-x-1 min-w-[120px] shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer bg-green-500 hover:bg-green-600"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SheetSelection;
