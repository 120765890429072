import { useState, useEffect, ChangeEvent } from "react";
import * as XLSX from "xlsx";
import { backendUrl } from "../constants";
import * as Sentry from "@sentry/browser";
import UploadButton from "./UploadButton.tsx";
import SheetSelection from "./SheetSelection";
import ProgressBox from "./ProgressBox";
import SuccessModal from "./SuccessModal.tsx";

const ExcelUploader = () => {
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheets, setSelectedSheets] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "success" | "failure" | "progress" | null
  >(null);
  const [message, setMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [taskId, setTaskId] = useState<string | null>(null);

  const clearSheets = () => {
    setSheets([]);
    setSelectedSheets([]);
    setFileName("");
    setFile(null);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      setFile(uploadedFile);

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        setSheets(workbook.SheetNames);
      };
      reader.readAsArrayBuffer(uploadedFile);
    }
  };

  const handleSubmit = async () => {
    if (!file || selectedSheets.length === 0) {
      setMessage("Please select a file and at least one sheet.");
      setUploadStatus("failure");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sheets", JSON.stringify(selectedSheets));

    try {
      const response = await fetch(`${backendUrl}/upload_excel`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setTaskId(result.task_id); // Assuming backend returns task_id
        setMessage("Upload in progress...");
        setUploadStatus("progress");
      } else {
        setMessage("Upload failed. Please try again.");
        setUploadStatus("failure");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setUploadStatus("failure");
      console.error("Error:", error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!taskId) return;

    const eventSource = new EventSource(`${backendUrl}/progress/${taskId}`);

    eventSource.onmessage = (event) => {
      try {
        const progressData = JSON.parse(event.data); // Parse the incoming JSON data

        setProgress(progressData.percent_complete);

        if (progressData.percent_complete === 100) {
          if (progressData?.status?.toLowerCase().includes("error")) {
            setUploadStatus("failure");
            setMessage(progressData?.message);
            eventSource.close();
            var error_message = Sentry.captureMessage(progressData?.message);
            console.log("captured exception");
            console.log(error_message);
          } else {
            setUploadStatus("success");
            setMessage(progressData?.status);
            eventSource.close();
          }
        } else if (progressData.status === "Error") {
          setUploadStatus("failure");
          setMessage(progressData.message || "An error occurred.");
          eventSource.close();
        }
      } catch (error) {
        console.error("Error parsing progress data:", error);
        setUploadStatus("failure");
        setMessage("Error occurred while tracking progress.");
        eventSource.close();
      }
    };

    eventSource.onerror = () => {
      setMessage("An error occurred while tracking progress.");
      setUploadStatus("failure");
      eventSource.close();
      setUploadStatus("progress");
    };

    return () => eventSource.close();
  }, [taskId]);

  return (
    <div className="flex justify-start">
      <div className="bg-gray-100 dark:bg-gray-900 px-5 py-5 w-[96%] sm:w-[80%] md:w-[70%] lg:w-[50%] mx-auto mt-10">
        <section className="container grid grid-cols-8">
          <h1 className="col-span-6 text-xl font-bold tracking-wide dark:text-gray-50">
            Upload Excel To Fund Facts &nbsp;
          </h1>
          <UploadButton
            onFileChange={handleFileChange}
            disabled={sheets.length > 0 ? true : false}
          />
        </section>
        {sheets.length > 0 && (
          <SheetSelection
            sheets={sheets}
            selectedSheets={selectedSheets}
            setSelectedSheets={setSelectedSheets}
            handleSubmit={handleSubmit}
            clearSheets={clearSheets}
            setUploadStatus={setUploadStatus}
          />
        )}
        {uploadStatus != null && (
          <ProgressBox
            fileName={fileName}
            selectedSheets={selectedSheets}
            progress={progress}
          />
        )}
        {uploadStatus === "success" && <SuccessModal message={message} />}
        {uploadStatus === "failure" && (
          <div className="bg-red-100 my-2 py-2 px-2 shadow-md text-red-500">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExcelUploader;
