import React from "react";

interface ProgressBoxProps {
  fileName: string;
  selectedSheets: string[];
  progress: number;
}

const ProgressBox: React.FC<ProgressBoxProps> = ({
  fileName,
  selectedSheets,
  progress,
}) => {
  return (
    <div className="bg-gray-50 my-5 p-4 shadow-md">
      <h4 className="text-lg font-semibold">Status Report</h4>
      <p className="text-sm">File: {fileName}</p>
      <p className="text-sm">Sheets: {selectedSheets.join(", ")}</p>
      <p className="text-sm">Progress: {progress}%</p>
    </div>
  );
};

export default ProgressBox;
