import React from "react";

interface SuccessModalProps {
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -20%)",
        backgroundColor: "#d4edda",
        padding: "20px",
        borderRadius: "5px",
        zIndex: 1000,
      }}
    >
      <h4>Upload Complete</h4>
      <p>{message}</p>
    </div>
  );
};

export default SuccessModal;
