import { useState } from "react";
import { backendUrl } from "../constants";

interface Snapshot {
  id: string;
  DBSnapshotIdentifier: string;
}

interface DatabaseSnapshotReplaceModalProps {
  onClose: () => void;
  dbIdentifier: string;
  snapshots: Snapshot[];
}

const DatabaseSnapshotReplaceModal: React.FC<
  DatabaseSnapshotReplaceModalProps
> = ({ onClose, dbIdentifier, snapshots }) => {
  const [selectedSnapshot, setSelectedSnapshot] = useState<string>("");

  const handleRestore = async () => {
    if (!selectedSnapshot) {
      alert("Please select a snapshot to restore.");
      return;
    }

    try {
      const response = await fetch(
        `${backendUrl}/restore_snapshot/${selectedSnapshot}/${dbIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message || "Restore successful!");
        onClose();
      } else {
        alert("Restore failed.");
      }
    } catch (error) {
      console.error("Error during restore:", error);
      alert("There was an error restoring the snapshot.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-yellow-100 p-6 text-center w-100">
        <p className="mb-4 text-xl">Restore Modal</p>
        <div className="grid grid-cols-5">
          <div className="col-span-1">
            <p className="text-right">Dest: &nbsp;</p>
          </div>
          <div className="col-span-4 py-1">
            <p className="text-green-500 bg-white border-2 px-2 text-left">
              {dbIdentifier}
            </p>
          </div>

          <div className="col-span-1 py-1">
            <p className="text-right">Source: &nbsp;</p>
          </div>
          <div className="col-span-4">
            <select
              value={selectedSnapshot}
              onChange={(e) => setSelectedSnapshot(e.target.value)}
              className="text-green-500 bg-white border-2 border-gray-300 px-2 py-1"
            >
              {snapshots.length > 0 ? (
                snapshots.map((snapshot) => (
                  <option key={snapshot.id} value={snapshot.id}>
                    {snapshot.DBSnapshotIdentifier}
                  </option>
                ))
              ) : (
                <option value="">No snapshots available</option>
              )}
            </select>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-5 space-x-4">
          <div className="col-span-3"></div>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-400 cols-span-1"
          >
            Cancel
          </button>
          <button
            onClick={handleRestore}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-400 cols-span-1"
          >
            Restore
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatabaseSnapshotReplaceModal;
