import { useState } from "react";
import useDatabases from "../hooks/useDatabases";
import DatabaseEntry from "./DatabaseEntry";
import SnapshotTable from "./SnapshotTable";
import { backendUrl } from "../constants";

interface Database {
  DBInstanceIdentifier: string;
  InstanceCreateTime: string;
  DBInstanceStatus: string;
}

const DatabaseView = () => {
  const { databases, snapshots, error } = useDatabases();

  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string | null>(null);

  const copyToClipboard = (index: number) => {
    const text = document.getElementById(`classname${index}`)?.textContent;
    if (text) {
      navigator.clipboard
        .writeText(text.trim())
        .then(() => alert("Copied to clipboard!"))
        .catch((err) => console.error("Failed to copy:", err));
    }
  };

  const handleDestroyClick = async (db: Database) => {
    setLoading(true);
    setFeedbackMessage(null); // Reset feedback message

    try {
      const response = await fetch(
        `${backendUrl}/delete_database/${db.DBInstanceIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the response has content before parsing as JSON
      if (response.ok) {
        const result = await response.text(); // Read response as text
        if (result) {
          setFeedbackMessage(
            `Database ${db.DBInstanceIdentifier} deleted successfully!`
          );
        } else {
          setFeedbackMessage(
            `Database ${db.DBInstanceIdentifier} deleted successfully, but no content returned.`
          );
        }
      } else {
        const errorText = await response.text();
        const errorMessage = errorText ? errorText : "Unknown error occurred";
        setFeedbackMessage(`Failed to delete database: ${errorMessage}`);
      }
    } catch (error) {
      setLoading(false);
      setFeedbackMessage(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const DatabaseList = ({
    databases,
    onCopyClick,
    onDestroyClick,
    snapshots,
  }: {
    databases: Database[];
    onCopyClick: (index: number) => void;
    onDestroyClick: (db: Database) => void;
    snapshots: any[];
  }) => {
    return (
      <div className="space-y-6">
        {databases.map((db, index) => (
          <DatabaseEntry
            key={index}
            db={db}
            onCopyClick={() => onCopyClick(index)} // Pass the index for copy function
            onDestroyClick={() => onDestroyClick(db)} // Pass the database object for destroy
            snapshots={snapshots}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="flex justify-start">
      <div className="bg-gray-100 dark:bg-gray-900 px-5 py-5 w-[96%] sm:w-[80%] md:w-[70%] lg:w-[50%] mx-auto mt-10">
        <section className="container grid grid-cols-8">
          <h1 className="col-span-6 text-xl font-bold tracking-wide dark:text-gray-50">
            Database Management &nbsp;
          </h1>
        </section>

        {feedbackMessage && (
          <div
            className={`p-4 mb-4 rounded-md text-sm ${
              feedbackMessage.startsWith("Error")
                ? "bg-red-100 text-red-800"
                : "bg-green-100 text-green-800"
            }`}
          >
            {feedbackMessage}
          </div>
        )}

        {loading && (
          <div className="text-center text-blue-600 p-4">Processing...</div>
        )}

        <DatabaseList
          databases={databases}
          onCopyClick={copyToClipboard}
          onDestroyClick={handleDestroyClick} // Update to handle API call
          snapshots={snapshots}
        />
        <SnapshotTable snapshots={snapshots} error={error} />
      </div>
    </div>
  );
};

export default DatabaseView;
