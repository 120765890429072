import React, { useState } from "react";
import { backendUrl } from "../constants";
import MessageModal from "./MessageModal";

interface CreateSnapshotButtonProps {
  dbIdentifier: string;
  deleting: boolean;
  maintaining: boolean;
}

const CreateSnapshotButton: React.FC<CreateSnapshotButtonProps> = ({
  dbIdentifier,
  deleting,
  maintaining,
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleCloseModal = () => {
    setMessage("");
  };

  const handleCreateSnapshot = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts
      const response = await fetch(
        `${backendUrl}/create_snapshot/${dbIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ db_identifier: dbIdentifier }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);
      setMessage(
        `Snapshot created: ${
          data.snapshot_id ||
          "Success. This will take a while. Please view the snapshots below."
        }`
      );
    } catch (error) {
      console.error("Snapshot creation failed", error);
      setMessage("Failed to create snapshot.");
    } finally {
      setLoading(false); // Set loading back to false after the async call finishes
    }
  };

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={handleCreateSnapshot}
        className={`min-w-[120px] px-4 gap-x-1 shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer ${
          loading || deleting || maintaining
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-green-500 hover:bg-green-600"
        }`}
        disabled={loading || deleting || maintaining}
      >
        {loading ? "... creating ..." : "Take Snapshot"}
      </button>
      {message && <MessageModal message={message} onClose={handleCloseModal} />}
    </div>
  );
};

export default CreateSnapshotButton;
