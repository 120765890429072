import React, { useState, useEffect } from "react";
import RestoreSnapshotButton from "./RestoreSnapshot";

interface Snapshot {
  DBSnapshotIdentifier: string;
  SnapshotCreateTime: string;
  Status: string;
}

interface SnapshotTableProps {
  snapshots: Snapshot[];
  error?: string | null;
}

const SnapshotTable: React.FC<SnapshotTableProps> = ({ snapshots, error }) => {
  const [sortedSnapshots, setSortedSnapshots] = useState<Snapshot[]>([]);
  const [sortColumn, setSortColumn] =
    useState<keyof Snapshot>("SnapshotCreateTime");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  useEffect(() => {
    sortData("SnapshotCreateTime", "desc");
  }, [snapshots]);

  const sortData = (column: keyof Snapshot, order?: "asc" | "desc") => {
    const newSortOrder =
      order || (column === sortColumn && sortOrder === "asc" ? "desc" : "asc");

    const sortedData = [...snapshots].sort((a, b) => {
      let valueA: string | number = a[column];
      let valueB: string | number = b[column];

      if (column === "SnapshotCreateTime") {
        valueA = new Date(a.SnapshotCreateTime).getTime();
        valueB = new Date(b.SnapshotCreateTime).getTime();
      }

      if (valueA < valueB) return newSortOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return newSortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortedSnapshots(sortedData);
    setSortColumn(column);
    setSortOrder(newSortOrder);
  };

  if (!snapshots || snapshots.length === 0) {
    return <p>Loading snapshots...</p>;
  }

  return (
    <div className="grid place-content-center w-90">
      <h1 className="col-span-6 py-2 text-xl font-bold tracking-wide dark:text-gray-50">
        Available Snapshots
      </h1>
      {error && error.length > 0 && (
        <p className="text-red-500 bg-slate-500">{error}</p>
      )}
      <table className="w-100 bg-green-100 w-90" cellPadding="8">
        <thead>
          <tr>
            <th
              onClick={() => sortData("DBSnapshotIdentifier")}
              className="cursor-pointer"
            >
              Snapshot Name{" "}
              {sortColumn === "DBSnapshotIdentifier"
                ? sortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </th>
            <th
              onClick={() => sortData("SnapshotCreateTime")}
              className="cursor-pointer"
            >
              Created{" "}
              {sortColumn === "SnapshotCreateTime"
                ? sortOrder === "asc"
                  ? "▲"
                  : "▼"
                : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedSnapshots.map((db, index) => (
            <tr key={index}>
              <td>
                <pre className="text-xs font-mono p-2 bg-gray-100 w-full text-right flex justify-between items-center">
                  <code className="text-md text-gray-800">
                    {db.DBSnapshotIdentifier}
                  </code>
                </pre>
                <div className="flex items-center space-x-2">
                  <RestoreSnapshotButton
                    snapshotIdentifier={db.DBSnapshotIdentifier}
                  />
                  <text className="text-xs">{db.Status}</text>
                </div>
              </td>
              <td className="text-xs">
                {new Date(db.SnapshotCreateTime).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SnapshotTable;
