import React from "react";

interface MessageModalProps {
  message: string | null; // message can either be a string or null
  onClose: () => void; // onClose is a function that takes no arguments and returns void
}

const MessageModal: React.FC<MessageModalProps> = ({ message, onClose }) => {
  if (!message) return null; // If no message, don't render the modal

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-80">
        <p className="text-sm text-gray-700">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 hover:bg-red-600 text-white rounded-full px-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default MessageModal;
