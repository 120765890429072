import { useState, useEffect } from "react";
import { backendUrl } from "../constants";

const useDatabases = () => {
  const [databases, setDatabases] = useState<any[]>([]);
  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDatabases = async () => {
      try {
        const response = await fetch(`${backendUrl}/db_instances`);
        if (!response.ok) {
          throw new Error("Failed to fetch database instances");
        }
        const data = await response.json();
        console.log('data.databases')
        console.log(data.databases)

        setDatabases(data.databases || []);
        setSnapshots(data.snapshots || []);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDatabases();
  }, []);

  return { databases, snapshots, error, loading };
};

export default useDatabases;
