import React from "react";
import { useAccessToken } from "../hooks/useAuthentication";

interface HeaderProps {
  setView: (view: string) => void;
  view: string;
}

const Header: React.FC<HeaderProps> = ({ setView, view }) => {
  const { instance, accounts } = useAccessToken();

  return (
    <header className="p-2 shadow-md bg-slate-200">
      <nav className="mx-1 my-2">
        <section className="container grid grid-cols-4 sm:grid-cols-8">
          <h1 className="col-span-4 text-2xl font-bold tracking-wide">
            Excellerate Your Fund Facts &nbsp;
          </h1>
          <div className="space-x-4 col-span-1 flex justify-start">
            <div
              onClick={() => setView("upload")}
              className={`px-4 py-2 cursor-pointer transition w-full text-center ${
                view === "upload"
                  ? "bg-gray-400 text-gray-50"
                  : "bg-gray-100 hover:bg-gray-600 hover:text-gray-50"
              }`}
            >
              Upload
            </div>
          </div>
          <div className="space-x-4 col-span-1 flex justify-start">
            <div
              onClick={() => setView("generate")}
              className={`px-4 py-2 cursor-pointer transition w-full text-center ${
                view === "generate"
                  ? "bg-gray-400 text-gray-50"
                  : "bg-gray-100 hover:bg-gray-600 hover:text-gray-50"
              }`}
            >
              Generate
            </div>
          </div>
          <div className="space-x-4 col-span-1 flex justify-start">
            <div
              onClick={() => setView("database")}
              className={`px-4 py-2 cursor-pointer transition w-full text-center ${
                view === "database"
                  ? "bg-gray-400 text-gray-50"
                  : "bg-gray-100 hover:bg-gray-600 hover:text-gray-50"
              }`}
            >
              Database
            </div>
          </div>

          {/* Log In/Log Out Tab */}
          <div className="space-x-4 col-span-1 flex justify-end w-full">
            {accounts?.length > 0 ? (
              <div
                onClick={() => instance?.logoutRedirect()}
                className="px-4 py-2 cursor-pointer bg-cherryBlossomPink hover:bg-red-700 hover:text-gray-50 transition w-full text-center"
              >
                Log Out
              </div>
            ) : (
              <div
                onClick={() => instance?.loginRedirect()}
                className="px-4 py-2 cursor-pointer bg-gray-100 hover:bg-blue-700 transition w-full text-center"
              >
                Log In
              </div>
            )}
          </div>
        </section>
      </nav>
    </header>
  );
};

export default Header;
