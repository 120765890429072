// UploadButton.tsx
import React from "react";

interface UploadButtonProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}
const UploadButton: React.FC<UploadButtonProps> = ({
  onFileChange,
  disabled,
}) => {
  return (
    <div className="col-span-2 flex items-center justify-center">
      <input
        type="file"
        accept=".xlsx, .xls"
        id="file-upload"
        onChange={onFileChange}
        className="hidden"
        disabled={disabled} // Disable the input element when the button is disabled
      />
      <label
        htmlFor="file-upload"
        className={`min-w-[120px] px-4 gap-x-1 shadow-md rounded-full inline-flex items-center justify-center text-white cursor-pointer ${
          disabled
            ? "bg-gray-400 cursor-not-allowed" // Grey background and disable the cursor
            : "bg-green-500 hover:bg-green-600"
        }`}
      >
        <span>Select File</span>
      </label>
    </div>
  );
};

export default UploadButton;
